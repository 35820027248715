import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line
import { useQueryParams } from 'q3-ui-queryparams';
import { useTranslation } from 'q3-ui-locale';
import { Builders } from 'q3-ui-forms';
import RefreshIcon from '@material-ui/icons/Refresh';
import useDecodedSearch from '../useDecodedSearch';
import RegistrationCtaBox from '../RegistrationCtaBox';

const PayPalQueryForm = ({ type }) => {
  const decoded = useDecodedSearch();
  const { t } = useTranslation('labels');
  const { encode } = useQueryParams();

  const handleSubmit = (values) => navigate(encode(values));

  return (
    <RegistrationCtaBox>
      <Typography variant="overline" color="secondary">
        {t('labels:configurePurchase')}
      </Typography>
      <Builders.Form
        initialValues={{
          code: '',
          quantity: 1,
          ...decoded,
        }}
        onSubmit={handleSubmit}
        enableSubmit={false}
      >
        <Builders.Field
          disabled={type !== 'Course Purchase'}
          name="quantity"
          type="number"
          min="1"
          xl={12}
          lg={12}
        />

        <Builders.Field
          name="code"
          label={t('discountCode')}
          type="text"
          xl={12}
          lg={12}
        />
        <Grid item xs={12}>
          <Button
            color="secondary"
            variant="outlined"
            type="submit"
            endIcon={<RefreshIcon />}
          >
            {t('update')}
          </Button>
        </Grid>
      </Builders.Form>
    </RegistrationCtaBox>
  );
};

PayPalQueryForm.defaultProps = {
  type: 'Other',
};

PayPalQueryForm.propTypes = {
  type: PropTypes.string,
};

export default PayPalQueryForm;
