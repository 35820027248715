import React from 'react';
import PropTypes from 'prop-types';
import useRest from 'q3-ui-rest';
import { useTranslation } from 'q3-ui-locale';
import Alert from '@material-ui/lab/Alert';
import PublicPageContent from '../PublicPageContent';
import useDecodedSearch from '../useDecodedSearch';

const ProductRegistrationView = ({ children }) => {
  const { product } = useDecodedSearch();
  const { t } = useTranslation('descriptions');

  const r = useRest({
    key: 'product',
    runOnInit: true,
    url: `/products/${product}`,
  });

  if (r.fetching) return t('hangTight');
  if (r.fetchingError)
    return (
      <Alert severity="error">
        {t('errorLoadingProduct')}
      </Alert>
    );

  return (
    <>
      <PublicPageContent
        text="payForProduct"
        vars={r.product}
      />
      {children(r.product)}
    </>
  );
};

ProductRegistrationView.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ProductRegistrationView;
