import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Box } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import { string } from 'q3-ui-helpers';
import useDecodedSearch from '../useDecodedSearch';

const PayPalDiscount = ({ currency, discount }) => {
  const { t } = useTranslation();
  const { code = '' } = useDecodedSearch();

  return (
    discount > 0 && (
      <Box mb={0.5}>
        <Alert severity="info">
          {t('descriptions:couponCodeApplied', {
            currency,
            value: string.toPrice(discount),
            code,
          })}
        </Alert>
      </Box>
    )
  );
};

PayPalDiscount.propTypes = {
  currency: PropTypes.string,
  discount: PropTypes.number,
};

PayPalDiscount.defaultProps = {
  currency: 'CAD',
  discount: 0,
};

export default PayPalDiscount;
