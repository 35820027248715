import React from 'react';
import { AuthContext } from 'q3-ui-permissions';
import { get } from 'lodash';

/**
 * @TODO
 * Should reference these constants in other files
 * */
export const ADMINISTRATOR = 'Administrator';
export const MASTER_ADMINISTRATOR = 'Master Administrator';
export const ADMINISTRATOR_ASSISTANT =
  'Administrator Assistant';

export const COACH = 'Coach';
export const MEMBER = 'Member';

const useUserRoleOptions = (roleReference = 'role') => {
  const role = get(
    React.useContext(AuthContext)?.state?.profile,
    roleReference,
    'role',
  );

  const is = (expectedRole) => role === expectedRole;

  return () => {
    const output = [];

    if (is(MASTER_ADMINISTRATOR))
      output.push(
        MASTER_ADMINISTRATOR,
        ADMINISTRATOR,
        ADMINISTRATOR_ASSISTANT,
        COACH,
      );

    if (is(ADMINISTRATOR))
      output.push(
        ADMINISTRATOR,
        ADMINISTRATOR_ASSISTANT,
        COACH,
      );

    if (is(ADMINISTRATOR_ASSISTANT))
      output.push(ADMINISTRATOR_ASSISTANT, COACH);

    if (is(COACH)) output.push(COACH);

    return output.concat(MEMBER).map((value) => ({
      label: value,
      value,
    }));
  };
};

export default useUserRoleOptions;
