import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import {
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Box,
  Grid,
} from '@material-ui/core';
import { isNumber, get, find, first, isNil } from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import { string } from 'q3-ui-helpers';
import useTax from '../useTax';

const findByTenure = (tenure) => (plan) =>
  find(
    get(plan, 'plan.billing_cycles', []),
    (cycle) => cycle.tenure_type === tenure,
  );

const getFee = (plan) =>
  get(
    plan,
    // setup fee
    'plan.payment_preferences.setup_fee.value',
    get(
      // product price
      first(get(plan, 'plan.purchase_units', [])),
      'amount.breakdown.item_total.value',
      0,
    ),
  );

export const getSubscription = findByTenure('REGULAR');
export const getTrial = findByTenure('TRIAL');

const PayPalSummary = (props) => {
  const { t } = useTranslation();
  const { currency, quantity, type } = props;
  const { getTaxFromPaypalSpec } = useTax();

  const fee = getFee(props);
  const tax = getTaxFromPaypalSpec(props);
  const subscription = getSubscription(props);
  const trial = getTrial(props);

  const style = {
    padding: 8,
    whiteSpace: 'nowrap',
  };

  return (
    <>
      <Grid container>
        <Grid item />
      </Grid>
      {!isNil(trial) && (
        <Box mb={0.5}>
          <Alert>
            {t('descriptions:trialEnabled', trial)}
          </Alert>
        </Box>
      )}
      <Box my={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th">
                {t('labels:charge')}
              </TableCell>
              <TableCell component="th">
                {t('labels:amount')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fee > 0 && (
              <TableRow>
                <TableCell>
                  {t('labels:oneTimeSetupFee')}
                </TableCell>
                <TableCell style={style}>
                  {string.toPrice(fee)}
                </TableCell>
              </TableRow>
            )}
            {!isNil(subscription) && (
              <TableRow>
                <TableCell style={{ padding: 8 }}>
                  {type === 'Course Purchase' &&
                  isNumber(quantity)
                    ? t(
                        'labels:subscriptionFeeCoursePurchase',
                        {
                          ...subscription,
                          quantity,
                        },
                      )
                    : t(
                        'labels:subscriptionFee',
                        subscription,
                      )}
                </TableCell>
                <TableCell style={style}>
                  {string.toPrice(
                    get(
                      subscription,
                      'pricing_scheme.fixed_price.value',
                    ),
                  )}
                </TableCell>
              </TableRow>
            )}
            {tax > 0 && (
              <TableRow>
                <TableCell>{t('labels:tax')}</TableCell>
                <TableCell style={style}>
                  {/** subscription doesn't have tax calc */}
                  {!isNil(subscription)
                    ? `${Number(tax).toFixed(3)}%`
                    : `$${Number(tax).toFixed(2)}`}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{t('labels:currency')}</TableCell>
              <TableCell style={style}>
                {currency}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

PayPalSummary.defaultProps = {
  currency: 'CAD',
  quantity: 0,
  type: 'Other',
};

PayPalSummary.propTypes = {
  currency: PropTypes.string,
  quantity: PropTypes.number,
  type: PropTypes.string,
};

export default PayPalSummary;
