import React from 'react';
import { Link } from 'gatsby';
import Alert from '@material-ui/lab/Alert';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import useTax from '../useTax';

// eslint-disable-next-line
const RegistrationRegionAlert = ({ children }) => {
  const { t } = useTranslation();

  return useTax().requiresChangeToProfile() ? (
    <Box mb={2}>
      <Alert
        action={
          <Button
            component={Link}
            to="/app/account/locale-2"
          >
            {t('labels:update')}
          </Button>
        }
        severity="warning"
      >
        {t('descriptions:noTax')}
      </Alert>
    </Box>
  ) : (
    children
  );
};

export default RegistrationRegionAlert;
