import React from 'react';
import useProfileForm from 'q3-admin/lib/hooks/useProfileForm';
import PayPalButtonsSmart from '../PayPalButtonsSmart';
import PayPalButtonsCustom from '../PayPalButtonsCustom';

const PayPalButtons = (props) => {
  const profile = useProfileForm()?.initialValues;

  return !profile?.subscriptionId ||
    profile?.hasCancelled ? (
    <PayPalButtonsSmart {...props} />
  ) : (
    <PayPalButtonsCustom {...props} />
  );
};

export default PayPalButtons;
