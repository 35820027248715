import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { navigate } from 'gatsby';
import Alert from '@material-ui/lab/Alert';
import { Box } from '@material-ui/core';
import { isNil } from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import usePayPalScript from '../usePayPalScript';
import { stringify } from '../utils';

const PayPalButtonsSmart = ({
  children,
  currency,
  plan,
  // eslint-disable-next-line
  plan_id,
  email,
}) => {
  const isSubscription = !isNil(plan_id);

  const [error, setError] = React.useState();
  const { t } = useTranslation('labels');
  const paypal = usePayPalScript(currency, isSubscription);

  const paypalButtonSpecification = {
    onApprove(data) {
      return axios
        .post('/subscription', {
          email,
          orderId: data.orderID,
          subscriptionId: data.subscriptionID,
        })
        .then(() => {
          navigate('/paid-registration-done');
        });
    },

    onCancel() {},

    onError(err) {
      setError(err);
    },

    ...(isSubscription
      ? {
          createSubscription: (data, actions) =>
            actions.subscription.create({
              plan_id,
              plan,
            }),
        }
      : {
          createOrder: (data, actions) =>
            actions.order.create(plan),
        }),
  };

  const getPaypalKey = () =>
    `SmartPaymentButtons-${new Date().toISOString()}-${stringify(
      error,
    )}`;

  React.useEffect(() => {
    if (paypal)
      paypal
        .Buttons(paypalButtonSpecification)
        .render('#checkout');
  }, [paypal, stringify(plan)]);

  return (
    <div id="checkout" key={getPaypalKey()}>
      {error && (
        <Box mb={0.5}>
          <Alert severity="error">
            {t('descriptions:paypalError')}
          </Alert>
        </Box>
      )}
      {children}
    </div>
  );
};

PayPalButtonsSmart.defaultProps = {
  children: null,
  currency: 'CAD',
  plan_id: null,
};

PayPalButtonsSmart.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
  currency: PropTypes.string,
  email: PropTypes.string.isRequired,
  plan_id: PropTypes.string,
  // eslint-disable-next-line
  plan: PropTypes.object.isRequired,
};

export default PayPalButtonsSmart;
