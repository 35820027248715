import React from 'react';
import { browser } from 'q3-ui-helpers';
import { get } from 'lodash';

const useScript = (src, namespace) => {
  const [init, setInit] = React.useState(false);
  const isReady = browser.isBrowserReady();

  React.useEffect(() => {
    if (isReady) {
      const jsScript = document.createElement('script');

      jsScript.src = src;
      jsScript.addEventListener('load', () =>
        setInit(true),
      );

      document.body.appendChild(jsScript);
    }
  }, [isReady]);

  return init && isReady ? get(window, namespace) : null;
};

export default useScript;
