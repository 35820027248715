import React from 'react';
import useRest from 'q3-ui-rest';
import { useTranslation } from 'q3-ui-locale';
import { Container, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PayPalButtons from '../PayPalButtons';
import PayPalDiscount from '../PayPalDiscount';
import PayPalSummary from '../PayPalSummary';
import useDecodedSearch from '../useDecodedSearch';
import PayPalLinkSelect from '../PayPalLinkSelect';
import ProductRegistrationView from '../ProductRegistrationView';
import RegistrationRegionAlert from '../RegistrationRegionAlert';
import withRegistration from '../withRegistration';

const RegistrationPaid = withRegistration((props) => {
  const {
    code = '',
    quantity = 1,
    link,
    product,
  } = useDecodedSearch();

  const { t } = useTranslation();
  const search = '?'.concat(
    Object.entries({
      quantity: Math.max(quantity, 1),
      code,
      link,
      product,
    })
      .reduce((acc, curr) => {
        if (curr[1])
          // eslint-disable-next-line
          acc.push([curr[0], curr[1]].join('='));

        return acc;
      }, [])
      .join('&'),
  );

  const r = useRest({
    url: '/register',
    runOnInit: true,
    pluralized: 'products',
    key: 'product',
    location: {
      search,
    },
  });

  const mergedProps = {
    ...props,
    ...r,
    ...r.product,
    ...r.plan,
    quantity,
    link,
  };

  return r.fetchingError ? (
    <Alert severity="error">
      {t('descriptions:errorLoadingPaypalProduct')}
    </Alert>
  ) : (
    <Container maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <PayPalLinkSelect {...mergedProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RegistrationRegionAlert>
            <PayPalSummary {...mergedProps} />
            <PayPalDiscount {...mergedProps} />
            {!r.fetching && !r.fetchingError && (
              <PayPalButtons {...mergedProps} />
            )}
          </RegistrationRegionAlert>
        </Grid>
      </Grid>
    </Container>
  );
}, ProductRegistrationView);

export default RegistrationPaid;
