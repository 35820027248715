import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import axios from 'axios';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const PayPalButtonsCustom = ({ quantity }) => {
  const { t } = useTranslation('descriptions');
  const [error, setError] = React.useState();

  const handleAccept = () =>
    axios
      .patch('/subscription', {
        quantity,
      })
      .then((res) => {
        const url = res?.data?.url;
        if (url) window.location.href = url;
        else navigate('/app/account/subscription');
      })
      .catch((e) => {
        setError(e);
      });

  return (
    <>
      <Typography>
        <small>{t('helpers:productQuantity')} </small>
      </Typography>
      <Typography>
        <small>
          <strong>
            {t('helpers:productQuantityDowngrade')}
          </strong>
        </small>
      </Typography>
      {error && (
        <Box mt={1}>
          <Alert severity="error">
            {t('descriptions:subscriptionUpdateError')}
          </Alert>
        </Box>
      )}
      <Box mt={1}>
        <Button
          onClick={handleAccept}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          endIcon={<NavigateNextIcon />}
        >
          {t('labels:changeSubscription')}
        </Button>
      </Box>
    </>
  );
};

PayPalButtonsCustom.defaultProps = {
  quantity: 1,
};

PayPalButtonsCustom.propTypes = {
  quantity: PropTypes.number,
};

export default PayPalButtonsCustom;
