import axios from 'axios';
import { isObject } from 'lodash';
import { navigate } from 'gatsby';
import {
  ADMINISTRATOR_ASSISTANT,
  ADMINISTRATOR,
  MASTER_ADMINISTRATOR,
} from './useUserRoleOptions/useUserRoleOptions';

export const createNewJournal = (values) =>
  axios.post('/journals', values).then(({ data }) => {
    navigate(`/app/journals/${data?.journal?.id}`);
  });

export const isAdmin = (role) =>
  [
    MASTER_ADMINISTRATOR,
    ADMINISTRATOR,
    ADMINISTRATOR_ASSISTANT,
  ].includes(role);

export const stringify = (xs) =>
  isObject(xs) ? JSON.stringify(xs) : '';

export const hasObjectValue = (obj) => (k, v) =>
  obj[k] === v;
